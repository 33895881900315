<template>
  <div class="sidebar">
    <el-menu
      class="sidebar-el-menu"
      :default-active="onRoutes"
      background-color="#324157"
      text-color="#bfcbd9"
      active-text-color="#20a0ff"
      unique-opened
      router
    >
      <el-menu-item index="/information">
        <i class="el-icon-menu"></i>
        <template #title>资讯管理</template>
      </el-menu-item>
      <el-menu-item index="/course">
        <i class="el-icon-s-flag"></i>
        <template #title>公司历程</template>
      </el-menu-item>
    </el-menu>
  </div>
</template>

<script>
// import bus from "../common/bus";
export default {
  data() {
    return {};
  },
  methods: {},

  computed: {
    onRoutes() {
      if (this.$route.path.indexOf("/information") >= 0) {
        return "/information";
      } else {
        return "/course";
      }
    },
  },
};
</script>

<style scoped>
.sidebar {
  display: block;
  position: absolute;
  left: 0;
  top: 70px;
  bottom: 0;
  overflow-y: scroll;
}
.sidebar::-webkit-scrollbar {
  width: 0;
}
.sidebar-el-menu:not(.el-menu--collapse) {
  width: 250px;
}
.sidebar > ul {
  height: 100%;
}
</style>
