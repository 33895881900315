import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import api from './api'
import utils from './utils/utils'
import installElementPlus from './plugins/element'
import './assets/css/main.css'
import './assets/css/reset.css'




import NProgress from 'nprogress'
import 'nprogress/nprogress.css'


const app = createApp(App)
installElementPlus(app)

app.config.globalProperties.$api = api
app.config.globalProperties.$loading = NProgress
app.config.globalProperties.$utils = utils
app.use(store).use(router).mount('#app')