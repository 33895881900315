import request from '../utils/request';

const api = {
    // 登录
    async login(params) {
        return await request.postRequest('/login', params)
    },
    async logOut() {
        return await request.postRequest('/logout')
    },
    async getCode() {
        return await request.getBlob('/captcha')
    },
    // 新闻管理
    newsManagement: {
        // 新闻列表
        async getNewList(type, params) {
            return await request.getRequest(`/home/news/queryPage/${type}`, params)
        },
        // 新闻新增
        async addNew(params) {
            return await request.post('/manage/news/insert', params)
        },
        // 新闻修改
        async putNew(id, params) {
            return await request.post(`/manage/news/update/${id}`, params)
        },
        // 新闻删除
        async deleteNew(id, params) {
            return await request.get(`/manage/news/delete/${id}`, params)
        },
        // 新闻置顶
        async topNew(id, params) {
            return await request.post(`/jll/manage/news/top/${id}`, params)
        },
        // 新闻详情
        async detailNew(id) {

            return await request.get(`/home/news/query/xywh/${id}`)
        },
        // 新闻查询
        async queryNewList(classification, params) {
            return await request.getRequest(`/home/news/queryPage/${classification}`, params)
        },
        //  公司历程 查询
        async companyHistory(params) {
            return await request.getRequest(`/manage/companyDevelop/queryDataPage`, params)
        },
        //  新增公司历程
        async addCompany(params) {
            return await request.post('/manage/companyDevelop/insert', params)
        },
        // 删除公司历程
        async deleteCompany(id, params) {
            return await request.get(`/manage/companyDevelop/delete/${id}`, params)
        },

        // 发布公司历程
        async releaseCompany(id, params) {
            return await request.get(`/manage/companyDevelop/queryDataByEvent`, params)
        },

    },
    // editor 上传图片

    async editorUpload(params) {
        return await request.post('/import/images', params)
    }



};

export default api