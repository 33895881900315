import axios from 'axios';
import qs from 'qs'
import Cookies from 'js-cookie'
import { ElMessage } from 'element-plus';
import router from "../router";



const service = axios.create({
    // process.env.NODE_ENV === 'development' 来判断是否开发环境
    // easy-mock服务挂了，暂时不使用了
    // baseURL: 'https://www.easy-mock.com/mock/592501a391470c0ac1fab128',
    timeout: 5000,
    withCredentials: true
});

// service.defaults.withCredentials = true
// 请求拦截器
service.interceptors.request.use((config) => {

    const token = Cookies.get('admin_token')
    if (token) {
        config.headers.Authorization = token
    }
    return config;
}, (err) => {
    return Promise.resolve(err);
});



service.interceptors.response.use((response) => {

    return response
}, (err) => {

    if (err && err.response) {
        switch (err.response.status) {
            case 401:
                router.push('/login')
                Cookies.remove("token");
                ElMessage.error('登录超时请重新登录');
                break;
            case 404:
                ElMessage.error('路径错误！')
                break;
            case 500:
                ElMessage.error('服务500错误！')
                break;
        }
    }
    return Promise.reject(err);
});


service.defaults.baseURL = 'https://api.xintans.com'

service.getFile = (url, params) => {
    return new Promise((resolve, reject) => {
        axios({
            method: 'get',
            url: `${url}`,
            params: params,
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};

service.getRequest = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: url,
            params: params,
            headers: {
                'Content-Type': 'application/json;charset=utf-8',
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};

// post
service.postRequest = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: url,
            data: qs.stringify(params),
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};


// post
service.getBlob = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'get',
            url: url,
            responseType: 'blob',
            data: params,
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};


service.post = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: url,
            data: params,
            // headers: {
            //     'Content-Type': 'application/json;',
            // }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};

// 上传文件
service.uploadFileRequest = (url, params) => {

    return new Promise((resolve, reject) => {
        service({
            method: 'post',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'multipart/form-data'
            }
        }).then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        });
    })
};

// put
service.putRequest = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'put',
            url: url,
            data: qs.stringify(params),
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};

service.put = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'put',
            url: url,
            data: params,
            headers: {
                'Content-Type': 'application/json',
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    })
};

// delete
service.deleteRequest = (url, params) => {
    return new Promise((resolve, reject) => {
        service({
            method: 'delete',
            url: url,
            params: params,
            headers: {
                'Cache-Control': 'no-cache',
                'Content-Type': 'application/x-www-form-urlencoded;charset=utf-8',
            }
        }).then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err.data)
        });
    });
};

export default service;
