import download from "downloadjs"
export default {
    // 筛选时间格式
    formatDate: (date, format) => {
        if (!date) {
            return '-'
        } else {
            format = format ? format : 'yyyy-MM-dd'
            date = date.replace(/-/g, '/')
            const t = new Date(date);
            if (/(y+)/.test(format)) {
                format = format.replace(RegExp.$1, (t.getFullYear() + '').substr(4 - RegExp.$1.length));
            }
            let o = {
                'M+': t.getMonth() + 1,
                'd+': t.getDate(),
                'h+': t.getHours(),
                'm+': t.getMinutes(),
                's+': t.getSeconds()
            };
            for (let k in o) {
                if (new RegExp(`(${k})`).test(format)) {
                    let str = o[k] + '';
                    format = format.replace(RegExp.$1, (RegExp.$1.length === 1) ? str : ('00' + str).substr(str.length));
                }
            }
            return format;
        }
    },
    // 下载
    download: (url) => {
        const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (reg.test(url)) {
        } else {
            url = `https://djxf.oss-cn-beijing.aliyuncs.com${url}`
        }
        download(url)
    },
    // 文件地址
    fileUrl: (url) => {
        const reg = /(http|https):\/\/([\w.]+\/?)\S*/;
        if (reg.test(url)) {
            return url
        } else {
            return `http://www.xintans.com${url}`
        }
    }
}
