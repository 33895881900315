import { createRouter, createWebHistory } from "vue-router";
import Cookies from 'js-cookie'

import Home from "../views/Home.vue";

// 个人认证列表
const information = () => import("../views/accountManage/information.vue")
// 团体认证列表
const course = () => import("../views/accountManage/course.vue")
// 新增新闻
const addNews = () => import("../views/accountManage/addNews.vue")
// 修改新闻
const editNews = () => import("../views/accountManage/editNews.vue")
// 新闻预览
const previewNews = () => import("../views/accountManage/preview.vue")

const routes = [
    {
        path: '/',
        redirect: '/information'
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        children: [
            {
                path: "/information",
                name: "authePersonal",
                meta: {
                    // title: '资讯管理'
                },
                component: information
            },
            {
                path: "/information/addnews",
                name: "autheAddnews",
                meta: {
                    // title: '个人认证列表'
                },
                component: addNews
            },
            {
                path: "/information/editNews/:id",
                name: "autheEditNews",
                meta: {
                    // title: '企业认证列表'
                },
                component: editNews

            },
            {
                path: "/information/previewNews",
                name: "authePreviewNews",
                meta: {
                    // title: '企业认证列表'
                },
                component: previewNews

            },
            {
                path: "/course",
                name: "autheGroup",
                meta: {
                    // title: '公司历程'
                },
                component: course

            },
        ]
    }, {
        path: "/login",
        name: "Login",
        meta: {
            title: '登录'
        },
        component: () => import(
            "../views/Login.vue")
    }
];

const router = createRouter({
    mode: 'history',
    history: createWebHistory(process.env.BASE_URL),
    routes
});

router.beforeEach((to, from, next) => {
    
    const token = Cookies.get('admin_token');
    
    if (!token||token==='undefined') {

        if (to.name === 'Login') {
            next();
            return
        }
        next({
            path: '/login',
        });
    } else {
        next();
    }
});

export default router;