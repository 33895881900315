import api from './../../api/index'
import { ElMessage } from 'element-plus';
import Cookies from 'js-cookie'
import jwt_decode from "jwt-decode";

const user = {
  state: {
    token: '',
    menu: '',
    user: null
  },

  mutations: {
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_MENU: (state, data) => {
      state.menu = JSON.stringify(data)
    },
    SET_USER: (state, data) => {
      state.user = data
    }
  },

  actions: {
    // 用户名登录
    usernameLogin({ commit }, userData) {

      return new Promise((resolve, reject) => {
        api.login(userData).then(res => {

          if (res.code === 0) {
            const data = res.data

            commit('SET_TOKEN', data)
            Cookies.set('admin_token', data)

            resolve(res)

          } else {

            // ElMessage.error(res.msg);
            resolve(res)

          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // // 获取用户信息
    GetUserInfo({ commit, state }) {
      return new Promise((resolve, reject) => {

      })
    },

    // 登出
    LogOut({ commit, state }) {
      return new Promise((resolve, reject) => {
        api.logOut().then(res => {

          if (res.code === 0) {
            Cookies.remove("admin_token");
            resolve()
          } else {
            ElMessage.error(res.msg);
          }
        }).catch(error => {
          reject(error)
        })
      })
    },

    // // 前端 登出
    // FedLogOut({ commit }) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', '')
    //     removeToken()
    //     resolve()
    //   })
    // },

    // // 动态修改权限
    // ChangeRoles({ commit }, role) {
    //   return new Promise(resolve => {
    //     commit('SET_TOKEN', role)
    //     setToken(role)
    //   })
    // }
  }
}

export default user
