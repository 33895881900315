
import { createStore } from 'vuex'
import user from './modules/user'
import getters from './getters'

import createPersistedState from 'vuex-persistedstate'

export default createStore({
    state: {
        tagsList: [],
        collapse: false
    },
    modules: {
        user
    },
    mutations: {
        clearTags(state) {
            state.tagsList = []
        },
        setTagsItem(state, data) {
            state.tagsList.push(data)
        },
        delTagsItem(state, data) {
            state.tagsList.splice(data.index, 1);
        },
        hadndleCollapse(state, data) {
            state.collapse = data;
        }
    },
    getters,
    plugins: [createPersistedState()]
})
